import * as React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <div className="container simple-page mt-6 mb-6">
      <h1>404</h1>
      <p>Sorry, deze pagina is niet beschikbaar!</p>
    </div>
  </Layout>
);

export default NotFoundPage;
